<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <article v-if="canCreateCashDeclarationForm">
        <h3 class="subheader">
          Quick Links
        </h3>
        <br />
        <section
          class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4"
        >
          <asom-button
            variant="primary"
            outline
            rounded="none"
            @click="$router.push('/cash-mgnt/cash-declaration-form/create')"
            icon="duplicate"
            text="New Cash Declaration Form"
          >
          </asom-button>
          <asom-button
            variant="primary"
            outline
            rounded="none"
            @click="
              $router.push('/cash-mgnt/cash-declaration-form/move-cash-bags')
            "
            icon="swap"
            text="Move Cash Bags"
          >
          </asom-button>
        </section>
      </article>

      <div class="flex flex-col md:w-2/3 sm:w-auto">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <caption class="hidden"></caption>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Bag Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Pending Witness Signature
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Completed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white">
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top"
                    >
                      TE Bags
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                    >
                      <div v-if="pendingWitnessBags" class="flex flex-col ">
                        <div
                          v-for="bag in pendingWitnessBags"
                          :key="bag.teBagId"
                          class="flex flex-row items-center space-x-4 space-y-2 align-center"
                        >
                          <div class="text-black">
                            {{ bag.teBagNo }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                    >
                      <div v-if="completedBags" class="flex flex-col ">
                        <div
                          v-for="bag in completedBags"
                          :key="bag.teBagId"
                          class="flex flex-row items-center space-x-4 space-y-2 align-center"
                        >
                          <div class="text-black">
                            {{ bag.teBagNo }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="bg-gray-50">
                  <tr>
                    <td
                      class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                    >
                      Amount of Cash
                    </td>
                    <td
                      class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                    >
                      {{ formatCurrency(pendingWitnessBagsTotal) }}
                    </td>
                    <td
                      class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                    >
                      {{ formatCurrency(completedBagsTotal) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isLoading" class="space-y-4">
        <!-- filter begin-->
        <asom-card title="Cash Declareation Form Search">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-form-field
              label="Date Created"
              required
              :state="inputStates('filters.dateRange')"
            >
              <asom-input-date-range
                v-model="filters.dateRange"
                datepicker-placement="bottom"
                :state="inputStates('filters.dateRange')"
              />
            </asom-form-field>
          </div>
          <template #footer>
            <asom-button
              text="Reset Filters"
              variant="secondary"
              @click="resetFilters"
            />
            <asom-button text="Apply" @click="getPageData(true)" />
          </template>
        </asom-card>
        <!-- filter end-->
        <asom-card title="Cash Declaration Form Transaction Log">
          <div class="sm:w-1/2 pb-6">
            <asom-form-field>
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:flex-1">Signature</label>
              <asom-input-checkbox
                v-model="signatureFilter"
                label="Items Pending Witness's Signature"
              />
            </asom-form-field>
          </div>
          <asom-client-table
            v-if="!isLoading"
            :columns="[
              'referenceNo',
              'witnessPersonNo',
              'baggerPersonNo',
              'baggerName',
              'witnessName',
              'formStatus',
              'teBagNo',
              'dateCreated',
              'amount',
              'sourceOfCashEnum',
              'action',
            ]"
            :data="tableData"
            :sortableColumns="[
              'referenceNo',
              'witnessPersonNo',
              'baggerPersonNo',
              'dateCreated',
              'amount',
              'formStatus',
              'teBagNo',
              'baggerName',
              'sourceOfCashEnum',
              'witnessName',
            ]"
            :searchableDateColumns="['dateCreated']"
            :searchableCurrencyColumns="['amount']"
          >
            <template v-slot:header_referenceNo>Reference No</template>
            <template v-slot:referenceNo="scopedSlots">
              <div class="flex flex-row">
                <asom-link @click="navigateToView(scopedSlots.rowData)">
                  <span
                    :class="scopedSlots.rowData.isDeleted && 'line-through'"
                    >{{ scopedSlots.data }}</span
                  >
                </asom-link>
              </div>
            </template>
            <template v-slot:header_dateCreated>Date Created</template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:amount="scopedSlots">{{
              getCDFAmount(scopedSlots)
            }}</template>
            <template v-slot:sourceOfCashEnum="scopedSlots">{{
              getCashEnum(scopedSlots.data)
            }}</template>

            <template v-slot:header_baggerName>Bagger Name</template>
            <template v-slot:header_witnessName>Witness Name</template>
            <template v-slot:header_formStatus>Form Status</template>
            <template v-slot:header_sourceOfCashEnum>Source of Cash</template>
            <template v-slot:formStatus="scopedSlots">
              <span>{{ scopedSlots.data }}</span>
            </template>
            <template v-slot:teBagNo="scopedSlots">
              <div class="flex flex-row">
                <span
                    :class="scopedSlots.rowData.isTeBagDeleted && 'line-through'"
                    >{{ scopedSlots.data }}
                </span>
              </div>
            </template>
            <template v-slot:action="scopedSlots">
              <div class="flex flex-row" v-if="showButton(scopedSlots)">
                <asom-button
                  size="sm"
                  outline
                  text="Delete"
                  variant="error"
                  @click="deleteCDF(scopedSlots.rowData)"
                />
              </div>
            </template>
          </asom-client-table>
        </asom-card>
      </div>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <asom-modal
      title="Delete Confirmation"
      v-model="showDeleteModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmittingDelete"
            :loading="isSubmittingDelete"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="toggleDeleteModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import filter from "lodash.filter";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getCashDeclarationFormList,
  deleteCashDeclarationForm,
} from "@/services/cashManagement.service";
import { useVuelidate } from "@vuelidate/core";
import { formatCurrency, add } from "../../../helpers/numbers";
import { cdf } from "../../../constants/APIEnums/cashManagementEnums";
import inputStates from "@/mixins/inputStates";
import moment from "moment";
import {toMoment} from "@/helpers/dateTimeHelpers";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "CashDeclarationList",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        }
      },
      cdfFormList: [],
      tableData: [],
      isLoading: true,
      completedBags: [],
      completedBagsTotal: 0,
      pendingWitnessBags: [],
      pendingWitnessBagsTotal: 0,
      pendingBaggerBags: [],
      pendingBaggerBagsTotal: 0,
      signatureFilter: false,
      formStatus: get(cdf, "cdfStatus", ""),
      selectedCDF: null,
      showDeleteModal: false,
      isSubmittingDelete: false,

      cashEnumList: ["PSM","Pre Encoded Ticket","Manual Change","Coin Flush","Others",],
    };
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      canCreateCashDeclarationForm: 'auth/canCreateCashDeclarationForm',
      canDeleteCashDeclarationForm: 'auth/canDeleteCashDeclarationForm',
    }),
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    },
    getTableData() {
      return this.cdfFormList.map((row) => {
        if (row.isDeleted) row.deleted = true;
        return row;
      });
    },
  },
  watch: {
    signatureFilter: function(newValue) {
      if (newValue) {
        this.tableData = filter(this.getTableData, {
          formStatusEnum: cdf.cdfStatus.PENDING_WITNESS_SIGNATURE.VALUE,
        });
      } else {
        this.tableData = this.getTableData;
      }
    },
  },
  mounted() {
    const searchVal = getStorage("cash-declaration-form");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.getPageData();
    })
  },
  methods: {
    get,
    displayUtcDate,
    formatCurrency,
    resetFilters() {
      this.filters.dateRange = null;
    },
    showButton({ rowData }) {
      return (
        get(rowData, "formStatusEnum") !== this.formStatus.COMPLETED.VALUE &&
        get(rowData, "formStatusEnum") !== this.formStatus.DELETED.VALUE &&
        this.canDeleteCashDeclarationForm
      );
    },
    getCashEnum(val){
      return this.cashEnumList[val - 1];
    },
    getCDFAmount({ rowData, data }) {
      if (get(rowData, "sourceOfCashEnum") === cdf.sourceOfCash.CoinFlush)
        return "N.A";
      return formatCurrency(data);
    },
    navigateToView(selectedForm) {
      if (
        selectedForm.formStatusEnum == 2 &&
        selectedForm.witnessId == this.userId
      ) {
        this.$router.push({
          name: "Cash Declaration Form Witness Sign",
          params: selectedForm,
        });
      } else if (
        selectedForm.formStatusEnum == 3 &&
        selectedForm.baggerId == this.userId
      ) {
        this.$router.push({
          name: "Cash Declaration Form Bagger Sign",
          params: selectedForm,
        });
      } else {
        this.$router.push({
          name: "Cash Declaration Form View",
          params: selectedForm,
        });
      }
    },
    deleteCDF(cdfDetails) {
      this.selectedCDF = cdfDetails;
      this.toggleDeleteModal(true);
    },
    toggleDeleteModal(value) {
      this.showDeleteModal = value;
    },
    async onSubmit() {
      this.isSubmittingDelete = true;
      const result = await deleteCashDeclarationForm({
        id: get(this.selectedCDF, "cashDeclarationFormId", ""),
      });
      if (result.success) {
        this.isSubmittingDelete = false;
        this.toggleDeleteModal(false);
        this.getPageData(true);
      } else {
        this.isSubmittingDelete = false;
        this.error = result.payload;
        this.toggleDeleteModal(false);
        this.$scrollTop();
      }
    },
    async getPageData(status) {
      if(status) setStorage("cash-declaration-form", this.filters);
      const resp = await getCashDeclarationFormList({ 
        activeOnly: false, 
        stationId: this.stationId, 
        lineId: this.lineId,
        ...this.queryParams 
      });
      if (resp.success) {
        this.cdfFormList = get(resp.payload, "list", []);
        this.tableData = this.cdfFormList;
        this.tableData.forEach((item) => {
          if (!item.isTeBagMovedOut && item.formStatusEnum === cdf.cdfStatus.COMPLETED.VALUE)
            item.formStatus = "Pending Move to AFC";
          if (item.isTeBagAtAFC && item.formStatusEnum !== cdf.cdfStatus.DELETED.VALUE)
            item.formStatus = "Moved in to AFC";
        });
        this.isLoading = false;
        this.setBagTypes(get(resp.payload, "list", []));
      } else return false;
    },
    setBagTypes(transactions) {
      function totalAmount(list) {
        let total = 0;
        list.forEach((bag) => {
          total = add(total, get(bag, "amount", 0));
        });
        return total;
      }
      this.completedBags = filter(transactions, {
        formStatusEnum: cdf.cdfStatus.COMPLETED.VALUE,
        isTeBagCollected: false,
      });
      this.pendingWitnessBags = filter(transactions, {
        formStatusEnum: cdf.cdfStatus.PENDING_WITNESS_SIGNATURE.VALUE,
      });
      this.pendingBaggerBags = filter(transactions, {
        formStatusEnum: cdf.cdfStatus.PENDING_BAGGER_SIGNATURE.VALUE,
      });
      this.completedBagsTotal = totalAmount(this.completedBags);
      this.pendingWitnessBagsTotal = totalAmount(this.pendingWitnessBags);
      this.pendingBaggerBagsTotal = totalAmount(this.pendingBaggerBags);
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    }
  },
};
</script>
